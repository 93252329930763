@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400;1,500&display=swap');

:root {
   --content-container-vertical-rhythm: var(--spacing-24);
   --f-sans: 'Raleway', sans-serif;
   --f-serif: 'Tiempos Headline', serif;
   --f-mono: sfmono-regular, menlo, monaco, consolas, 'Liberation Mono', 'Courier New', monospace;
   --fw-light: 300;
   --fw-regular: 400;
   --fw-medium: 500;
   --fw-semibold: 600;
   --fw-bold: 700;
   --fw-extrabold: 800;
   --fw-black: 900;
   --fs-14: 0.875rem;
   --fs-16: 1rem;
   --fs-18: 1.125rem;
   --fs-20: 1.25rem;
   --fs-24: 1.5rem;
   --fs-32: 2rem;
   --fs-40: 2.5rem;
   --fs-48: 3rem;
   --fs-56: 3.5rem;
   --fs-64: 4rem;
   --fs-72: 4.5rem;
   --fs-80: 5rem;
   --fs-96: 6rem;
   --container-width: 20.5rem;
   --column-gap: 2em;
   --box-shadow-default: 0 4px 4px rgba(0, 0, 0, 0.15);
   background-color: #f4f5f6;
   --background-nav: 'radial-gradient(59.93% 52.07% at 94.73% 11.33%, rgba(62, 189, 229, 0.20) 0%, rgba(0, 0, 0, 0.00) 100%), radial-gradient(97.2% 117.34% at 4.49% 37.13%, rgba(0, 41, 255, 0.20) 0%, rgba(112, 160, 96, 0.00) 97.23%), radial-gradient(73.02% 51.32% at 88.09% 79.24%, rgba(13, 153, 255, 0.20) 0%, rgba(177, 222, 255, 0.00) 100%), #F4F5F6; ',
}

* {
   box-sizing: border-box;
   padding: 0;
   margin: 0;
   font-family: var(--f-sans);
}

html,
body {
   overflow-x: hidden;
   /* background-color: #f4f5f6; */
}

body {
   /* background-color: #f4f5f6; */
   /* height: 100vh;
   overflow-y: hidden; */
   color: rgb(var(--foreground-rgb));
   background: linear-gradient(to bottom, transparent, rgb(var(--background-end-rgb))) rgb(var(--background-start-rgb));
}

a {
   color: inherit;
   text-decoration: none;
}

@media (prefers-color-scheme: dark) {
   html {
      color-scheme: dark;
   }
}

@media (min-width: 25.87em) {
   :root {
      --container-width: 23rem;
   }
}

@media (min-width: 48em) {
   :root {
      --container-width: 43rem;
   }
}

@media (min-width: 80em) {
   :root {
      --container-width: 71rem;
   }
}

@media (min-width: 90em) {
   :root {
      --container-width: 79rem;
   }
}

@media (min-width: 120em) {
   :root {
      --container-width: 103rem;
   }
}

input[type=number]::-webkit-inner-spin-button { 
   -webkit-appearance: none;
   
}

input[type=number] { 
  -moz-appearance: TextField;
  -webkit-appearance: TextField;
          appearance: TextField;

}

/*
 * Container style
 */
.ps {
  overflow: hidden !important;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;
}

/*
 * Scrollbar rail styles
 */
.ps__rail-x {
  display: none;
  opacity: 0;
  transition: background-color .2s linear, opacity .2s linear;
  -webkit-transition: background-color .2s linear, opacity .2s linear;
  height: 15px;
  /* there must be 'bottom' or 'top' for ps__rail-x */
  bottom: 0px;
  /* please don't change 'position' */
  position: absolute;
}

.ps__rail-y {
  display: none;
  opacity: 0;
  transition: background-color .2s linear, opacity .2s linear;
  -webkit-transition: background-color .2s linear, opacity .2s linear;
  width: 15px;
  /* there must be 'right' or 'left' for ps__rail-y */
  right: 0;
  /* please don't change 'position' */
  position: absolute;
}

.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
  display: block;
  background-color: transparent;
}

.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y,
.ps--focus > .ps__rail-x,
.ps--focus > .ps__rail-y,
.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y {
  opacity: 0.6;
}

.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
  background-color: #eee;
  opacity: 0.9;
}

/*
 * Scrollbar thumb styles
 */
.ps__thumb-x {
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color .2s linear, height .2s ease-in-out;
  -webkit-transition: background-color .2s linear, height .2s ease-in-out;
  height: 6px;
  /* there must be 'bottom' for ps__thumb-x */
  bottom: 2px;
  /* please don't change 'position' */
  position: absolute;
}

.ps__thumb-y {
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color .2s linear, width .2s ease-in-out;
  -webkit-transition: background-color .2s linear, width .2s ease-in-out;
  width: 6px;
  /* there must be 'right' for ps__thumb-y */
  right: 2px;
  /* please don't change 'position' */
  position: absolute;
}

.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x,
.ps__rail-x.ps--clicking .ps__thumb-x {
  background-color: #999;
  height: 11px;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
  background-color: #999;
  width: 11px;
}

/* MS supports */
@supports (-ms-overflow-style: none) {
  .ps {
    overflow: auto !important;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps {
    overflow: auto !important;
  }
}
.scrollbar-container {
  position: relative;
  height: 100%; }

/*# sourceMappingURL=styles.css.map*/
